import React, { useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import { loginLayoutStyles, LoginIllustration } from '../shared/elements'
import { TextField } from '../shared/fields'
import * as API from '../../services/api'

const Container = styled.div`
  ${loginLayoutStyles}
`

export default function ResetPasswordForm(props) {
  const { afterSubmit } = props

  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (values) => {
    setError('')
    setSubmitting(true)

    API.postRequest('/advertiser/forgot-password', values)
      .then((response) => {
        setSubmitting(false)
        afterSubmit()
      })
      .catch((error) => {
        setSubmitting(false)
        setError(error)
      })
  }

  return (
    <Container>
      <LoginIllustration head="Forgot your password?" />
      <div className="form-container">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <SVG src="/images/logo-dark.svg" className="head-logo" />
          <h3>Password reset</h3>

          <div className="mt-4">
            <TextField
              form={form}
              validation={{ required: true }}
              type="email"
              name="email"
              label="Email address"
            />
          </div>

          {error && <div className="text-danger mb-3">{error}</div>}

          <div className="actions mt-5">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary"
            >
              Send me a link
            </button>
          </div>
        </form>
      </div>
    </Container>
  )
}
